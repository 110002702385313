import React from "react";
import {
  SEO,
  PageLayout,
  MarkdownContent,
  BlogPostNavigation,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class BlogPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessBlogPost,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
          ogUrl={"https://www.devanasalon.com/blog/"}
        />
        <PageLayout className={"blog-page"} hero={false}>
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"blog-page-container"}
          >
            <Grid.Column width={16}>
              <div className={"blog-page-header"}>
                <Header as={"h1"} className={""}>
                  <span
                    children={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "blog_header",
                      defaultValue: "Blog",
                    })}
                  />
                  <span
                    className={"highlighted"}
                    children={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "blog_header_highlighted",
                    })}
                  />
                </Header>
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "blog_description",
                  })}
                />
              </div>
              <BlogPostNavigation
                title={""}
                description={""}
                featuredPostId={null}
                alwaysShowSearch={false}
                hideSearch={true}
                columns={3}
                pageSize={12}
                tight={true}
                dateFormat={"MMM d"}
                posts={allFishermanBusinessBlogPost.nodes}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Blog" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
    allFishermanBusinessBlogPost(filter: { destination: { eq: "BLOG" } }) {
      nodes {
        _id
        title
        slug
        author {
          firstName
          lastName
          avatar
          email
        }
        summary
        content
        heroImage
        gatsbyHeroImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        publishedAt
        heroImageAltText
      }
    }
  }
`;
